<template>
  <div class="modal-bonus-condition-content__body">
    <div v-if="bonusInfo?.name" class="modal-bonus-condition-content__body--row">
      <span>{{ getContent(popupsData, defaultLocalePopupsData, 'bonusCondition.name') }}:</span>
      <span>{{ bonusInfo.name }}</span>
    </div>

    <div v-if="props.bonusType === 'free-spin' && gameInfo" class="modal-bonus-condition-content__body--row">
      <span>{{ getContent(popupsData, defaultLocalePopupsData, 'bonusCondition.game') }}:</span>
      <nuxt-link :to="localizePath(`/game/${gameInfo.identity}?real=true`)" class="card-bonuses__link">
        {{ gameInfo.name }}
      </nuxt-link>
    </div>

    <div v-if="bonusInfo?.expiredAt" class="modal-bonus-condition-content__body--row">
      <span>{{ getContent(popupsData, defaultLocalePopupsData, 'bonusCondition.validTill') }}:</span>
      <span>{{ dayjs(bonusInfo?.expiredAt).format('DD-MM-YYYY HH:mm') }}</span>
    </div>

    <div v-if="bonusInfo?.maxWinAmount" class="modal-bonus-condition-content__body--row">
      <span>{{ getContent(popupsData, defaultLocalePopupsData, 'bonusCondition.maxWinAmount') }}:</span>
      <span>{{ maxWinAmountFormat }}</span>
    </div>

    <div v-if="bonusInfo?.maxBetAmount" class="modal-bonus-condition-content__body--row">
      <span>{{ getContent(popupsData, defaultLocalePopupsData, 'bonusCondition.maxBetAmount') }}:</span>
      <span>{{ bonusInfo.maxBetAmount }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IGame, IPlayerBonus, IPlayerFreeSpin } from '@skeleton/core/types';

  const dayjs = useDayjs();

  const props = defineProps<{
    showModal: boolean;
    title?: string;
    image?: string;
    description?: string;
    confirmButton?: string;
    cancelButton?: string;
    bonusesUpdating?: boolean;
    bonusInfo?: IPlayerBonus | IPlayerFreeSpin | undefined;
    wageringLabel?: string;
    bonusType?: 'bonus' | 'free-spin';
    gameInfo?: IGame | undefined;
  }>();

  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
  const { getContent, localizePath } = useProjectMethods();

  const { currencies } = storeToRefs(globalStore);

  const bonusCurrency = computed(() => currencies.value.find(currency => currency.code === props.bonusInfo?.currency));
  const maxWinAmountFormat = computed(() => `${bonusCurrency.value.symbol} ${props.bonusInfo?.maxWinAmount}`);
</script>

<style src="~/assets/styles/components/modal-content/bonus-condition.scss" lang="scss" />
