<template>
  <client-only>
    <layout-modal
      :showModal="showModal"
      modalClass="modal-bonus-condition"
      @close-modal="emit('closeModal')"
    >
      <template v-slot:header>
        <div class="title">
          {{ getContent(popupsData, defaultLocalePopupsData, 'bonusCondition.modalTitle') }}
        </div>
      </template>

      <modal-content-bonus-condition v-bind="props" />

      <template v-slot:footer>
        <button-base
          type="primary"
          size="md"
          @click="emit('closeModal')"
        >
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
        </button-base>
      </template>
    </layout-modal>
  </client-only>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { IPlayerBonus } from '@skeleton/core/types';

  const props = defineProps<{
    showModal: boolean,
    title?: string,
    image?: string,
    description?: string,
    confirmButton?: string,
    cancelButton?: string,
    bonusesUpdating?: boolean
    bonusInfo?: IPlayerBonus,
    wageringLabel?: string
  }>();

  const globalStore = useGlobalStore();
  const {
    popupsData,
    defaultLocalePopupsData,
    layoutData,
    defaultLocaleLayoutData,
  } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();

  const emit = defineEmits(['closeModal']);
</script>

<style src="~/assets/styles/components/modal/bonus-condition.scss" lang="scss" />
